import React, { Suspense } from 'react';

import AppRouter from './router'
//scss files
import "bootstrap/dist/css/bootstrap.min.css";
import { UseWalletProvider } from 'use-wallet'

function App() {
  return (
    <React.Fragment>
      <Suspense fallback={null}>
        <UseWalletProvider>
          <AppRouter />
        </UseWalletProvider>
      </Suspense>
    </React.Fragment>
  );
}

export default App;
