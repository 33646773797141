import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import "./index.css";
import { BrowserRouter } from "react-router-dom";
// import { ParallaxProvider } from 'react-scroll-parallax';
import 'antd/dist/antd.css';
ReactDOM.render(
  <React.Fragment>
    {/* <ParallaxProvider scrollAxis="horizontal"> */}
    <BrowserRouter>
      <App />
    </BrowserRouter>

    {/* </ParallaxProvider> */}
  </React.Fragment>,
  document.getElementById('root')
);